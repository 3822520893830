import React from 'react';
import { Section } from '../components/Section/Section';
import Layout from '../layouts';

const NotFoundPage = () => (
	<Layout>
		<div className="main">
			<Section>
				<div className="grid">
					<div className="col md-7 lg-5 md-push-1 lg-push-2 lg-push-3">
						<h1>404</h1>
						<h2>Non trovato</h2>
						<p>
							Spesso si decide di smarrire la via maestra semplicemente per fuggire da se stessi, <b>questa pagina non esiste</b> ma se veramente l’hai desiderata <b>un giorno comparirà per opera di un creativo.</b>
						</p>
					</div>
				</div>
			</Section>
		</div>
	</Layout>
);

export default NotFoundPage;
